.date-filter{
    display: flex;
    align-items: center;
    .MuiOutlinedInput-notchedOutline{
        border-radius: 0;
    }
}
.header-box{
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.05rem;
    font-weight: 600;
}
.content-box{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
}
.box-card{
    .MuiCardContent-root{
        display: flex;
        gap: 5px;
    }
}